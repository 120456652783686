
import { Button, SxProps, Theme, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dialog from "../Dialog";
import { lightTheme } from "../../assets/theme";

interface InternalNavProps {
  name: string;
  to: string;
  sx?: SxProps<Theme>;
  icon?: JSX.Element;
  callback?: () => any;
}

export function InternalNav(props: InternalNavProps) {

  const { icon, callback, name, sx, to } = props;

  const navigate = useNavigate();
  const { t } = useTranslation("translations");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOnClick = () => {
    if (!window.location.hash.endsWith("#!")) {
      if (callback) callback();
      navigate(to);
    } else {
      setDialogOpen(true);
    }
  }

  return (
    <>
      <Button
        variant="text"
        onClick={handleOnClick}
        fullWidth
        sx={{
          ...{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "start",
            borderRadius: 0,
            textTransform: "initial",
            color: lightTheme.palette.common.white
          }, ...sx
        }}
      >
        {icon && <>{icon}&nbsp;&nbsp;</>}
        {name}
      </Button>
      <ThemeProvider theme={lightTheme}>
        <Dialog
          type="confirm"
          dialogContentText={t("datatable.restore_dialog.content", {
            tableName: name
          })}
          handleClose={() => setDialogOpen(false)}
          handleConfirm={() => {
            setDialogOpen(false);
            navigate(to);
            if (callback) callback();
          }}
          open={dialogOpen}
          title={t("datatable.restore_dialog.title")}
        />
      </ThemeProvider>
    </>
  )
}