import { IconButton, IconButtonProps, Stack, useMediaQuery, useTheme } from "@mui/material";
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ReactElement } from "react";
import ActionResultBox, { ActionResultBoxProps } from "./ActionBox";
import { Download, Filter, FilterList } from "@mui/icons-material";

interface CustomToolbarProps {
  actionResult?: ActionResultBoxProps;
  buttons: ReactElement<IconButtonProps>[];
}

export default function TableToolbar(props: CustomToolbarProps) {
  const { actionResult, buttons } = props;
  const { type: action, message: content } = actionResult ?? {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <GridToolbarContainer>
      <Stack 
        direction={{ xs: 'column', md: 'row' }} 
        flex={1} 
        justifyContent="space-between" 
        alignItems={{ xs: 'stretch', md: 'center' }}
        spacing={2}
        overflow="scroll"
        p={1}
      >
        <Stack 
          direction="row" 
          spacing={1}
          
          justifyContent="start"
          width={{ xs: '100%', md: 'auto' }}
        >
          
          {
            isSmallScreen ? (
              <GridToolbarExport
              slotProps={{ button: { component: ({children, ...rest }) => {
                return (<IconButton {...rest} size="small"><Download /></IconButton> )
              } }}}
            />
            ) : <GridToolbarExport />
          }
         
          {!isSmallScreen && (
            <GridToolbarDensitySelector />
          )}
          
          { isSmallScreen ? (
            <GridToolbarFilterButton
              slotProps={{ button: { component: ({children, ...rest }) => {
                return (<IconButton {...rest} size="small"><FilterList /></IconButton> )
              } }}}
            />
          ) : (
            <GridToolbarFilterButton />
          )}
          
          {isSmallScreen && (
            <Stack direction="row" spacing={1}>
              {buttons}
            </Stack>
          )}
        </Stack>
        <Stack 
          direction="row" 
          flex={1} 
          justifyContent="center"
          width={{ xs: '100%', md: 'auto' }}
          order={{ xs: 3, md: 2 }}
        >
          <ActionResultBox type={action} message={content} />
        </Stack>
        {!isSmallScreen && (
          <Stack 
            direction="row" 
            spacing={1}
            order={{ xs: 2, md: 3 }}
          >
            {buttons}
          </Stack>
        )}
      </Stack>
    </GridToolbarContainer>
  )
}