import { createTheme } from "@mui/material";
import { customComponents } from "./customComponents";
import { etEE } from '@mui/material/locale';

export const lightTheme = createTheme({
  components: {
    ...customComponents
  },
  palette: {
    background: {
      default: "#f5f5f5"
    }
  }
}, etEE);

export const darkTheme = createTheme({
  components: {
    ...customComponents
  },
  palette: {
    mode: 'dark',
  }
}, etEE);