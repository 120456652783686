import { GridSingleSelectColDef, GridRenderCellParams, getGridDefaultColumnTypes, DEFAULT_GRID_COL_TYPE_KEY } from "@mui/x-data-grid";
import _, { includes } from "lodash";
import { ContentColumn } from "../../../hooks/azureTableHooks";
import { customSingleSelectOperator } from "../CustomFilterOperators";

const defaultColumnTypes = {
  ...getGridDefaultColumnTypes(),
  float: getGridDefaultColumnTypes().number,
}

const availableFilterOperators = [
  "equals", "startsWith", "endsWith", "contains", ">", "<", ">=", "<=", "=", "!=", "before", "after", "is", "isAnyOf"
]

export default function getDataColumnContent(c: ContentColumn): GridSingleSelectColDef {
  const filterOperators = defaultColumnTypes[c.dataType ?? DEFAULT_GRID_COL_TYPE_KEY]?.filterOperators  || defaultColumnTypes.string?.filterOperators;

  const filters = filterOperators?.filter(o => includes(availableFilterOperators, o?.value)) ?? [];

  const isSingleSelect = c.dataType === "singleSelect";

  const columnFilters = isSingleSelect ? [customSingleSelectOperator] : filters;

  const decimalPlaces = c.decimalPlaces || undefined;

  return {
    field: c.accessor,
    headerName: c.name,
    type: c.dataType as any,
    editable: !c.readonly,
    flex: c.width ? undefined : 1,
    valueOptions: c.valueOptions?.map(o => {
      return {
        value: o.value,
        label: o.label
      }
    }),

    valueParser: (value: any, row) => {
      if (c.dataType === "float" || c.dataType === "number") {
        if (typeof value === 'string') {
          // Trim the string and replace any spaces used as thousand separators
          let cleanValue = value.trim().replace(/\s/g, '');
      
          // Determine if comma or dot is used as decimal separator
          const lastCommaIndex = cleanValue.lastIndexOf(',');
          const lastDotIndex = cleanValue.lastIndexOf('.');
      
          let decimalSeparator, thousandSeparator;
      
          if (lastCommaIndex > lastDotIndex) {
            decimalSeparator = ',';
            thousandSeparator = '.';
          } else {
            decimalSeparator = '.';
            thousandSeparator = ',';
          }
      
          // Remove thousand separators
          cleanValue = cleanValue.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
      
          // Replace decimal separator with a dot if it's not already
          if (decimalSeparator !== '.') {
            cleanValue = cleanValue.replace(decimalSeparator, '.');
          }
      
          // Parse the cleaned value
          const parsedValue = parseFloat(cleanValue);
      
          // Return the parsed value if it's a valid number, otherwise return null
          return isNaN(parsedValue) ? null : parsedValue;
        }
        // If value is already a number, return it directly
        if (typeof value === 'number') {
          return value;
        }
      } else if (c.dataType === "singleSelect") {
        // If the value is not a number, try to find it in valueOptions
        if (typeof value === 'string' && isNaN(Number(value))) {
          const option = c.valueOptions?.find(opt => opt.label.toLowerCase() === value.toLowerCase());
          if (option) {
            return option.value;
          }
        }
        // If it's a number or not found in labels, return the original value
        return value;
      }
      // For other data types, return the original value
      return value;
    },

    pastedValueParser: (value, row) => {
      if (c.dataType === "singleSelect") {
        // If the value is not a number, try to find it in valueOptions
        if (typeof value === 'string' && isNaN(Number(value))) {
          const option = c.valueOptions?.find(opt => opt.label.toLowerCase() === value.toLowerCase());
          if (option) {
            return option.value;
          }
        }
        // If it's a number or not found in labels, return the original value
        return value;
      }
      return value;
    },

    valueGetter: (value, row) => {
      if (c.dataType === "dateTime" || c.dataType === "date") {
        if (!value) return undefined;
        return new Date(value as string);
      }
      if (c.dataType === "float" || c.dataType === "number") {
        if (value === null || value === undefined || c.accessor === "Ky") return value;
        const numValue = Number(value);
        if (isNaN(numValue)) return value; // Return original value if it's not a valid number
        return numValue.toLocaleString('et-EE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: decimalPlaces,
          useGrouping: c.thousandSeparator || false, // This enables thousand separators
        });
      }
      return value;
    },

    valueSetter: (value, row) => {
      if (c.dataType === "float" || c.dataType === "number") {
        if (typeof value === 'string') {
          // Remove any non-numeric characters except for comma and dot
          const cleanValue = value.replace(/[^\d,.]/g, '');
          // Replace comma with dot if comma is used as decimal separator
          const normalizedValue = cleanValue.includes(',') ? cleanValue.replace(',', '.') : cleanValue;
          const rawValue = parseFloat(normalizedValue);
          return { ...row, [c.accessor]: isNaN(rawValue) ? null : rawValue };
        }
        // If value is already a number, use it directly
        if (typeof value === 'number') {
          return { ...row, [c.accessor]: value };
        }
      }
      return { ...row, [c.accessor]: value };
    },
    width: c.width,
    cellClassName: c.readonly ? "readonly-cell" : "",
    filterOperators: columnFilters,
    filterable: columnFilters.length > 0,
    renderCell: (params: GridRenderCellParams) => { 
      if(c.accessor === "Ky" && !_.isNumber(params?.value)) return <span>#</span>
    },
  }
}