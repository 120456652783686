import fetcher from '../services/fetchJson';
import { TABLES_LIST_URL, TABLE_CONTENT_URL } from '../services/serverUrl';
import { GridColDef, GridRowsProp, GridSortDirection } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { debounce, orderBy } from 'lodash';
import { getCookie } from 'cookies-next';

export enum YesOrNo {
  Yes = 'Y',
  No = 'N'
}

export interface DataRow {
  RwIsCrrnt?: YesOrNo;
  Ky?: string;
  [key: string]: any;
}

export type TTableType = "FORM" | "TABLE";

export type TDataType = "string" | "number" | "dateTime" | "singleSelect" | "date" | "float";

export interface TableInfo {
  id: string;
  accessor: string;
  name: string;
  folderName: string;
  type: TTableType;
}

export interface ContentColumn {
  accessor: string;
  dataType: TDataType;
  name: string;
  valueOptions: { value: number, label: string }[];
  readonly: boolean;
  description: string;
  width: number;
  format?: any;
  decimalPlaces?: number;
  thousandSeparator?: boolean;
}

export interface FilterContent {
  title: string;
  key: string;
  selected: string;
  options: {
    value: string;
    name: string;
  }[];
}

export interface ContentInfo {
  name: string;
  folderName?: string;
  deletableRows: boolean;
  columns: ContentColumn[];
  rowsCount: number;
  rows: DataRow[];
  success: boolean;
  userAccess: "EDIT" | "VIEW" | "COMPANY";
  rawQueryBaseScript: string;
  errorMsg: string;
  filterContent?: FilterContent[];
  canSubmitForm: boolean;
  queryCompleted: boolean;
}

export interface TableContent {
  name: string;
  folderName?: string;
  columns: GridColDef[];
  rows: GridRowsProp;
}

export interface ContentRequestAttributes {
  rawQuery?: string;
  clauses?: FilterParams;
  pagination?: {
    offset: number;
    limit: number;
  };
  orderBy?: {
    field: string;
    sort: GridSortDirection;
  };
  filterFormQuery?: { [key: string]: string | number; };
}

export type FilterRange = [string, string];

export type FormFilterValue =  boolean | string | number | FilterRange | number[];

export interface FilterParams {
  [field: string]: FormFilterValue;
}

export interface SelectValue {
  value: number;
  label: string;
}

export function useTablesList() {
  const isAuthenticated = getCookie('adpt-auth-token');
  const { data: tablesList, refetch: mutateTablesList} = useQuery(
    ["tablesList"],
    () => fetcher(`${TABLES_LIST_URL}`, {
      method: 'GET',
    }),
    {
      enabled: !!isAuthenticated,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  const orderedList = useMemo(() => {
    return orderBy(tablesList, ['folderName', 'name'], 'asc' );
  }, [tablesList]);

  return { tablesList: orderedList, mutateTablesList }
}


export const useTableContent = (tableAccessor?: string, body?: ContentRequestAttributes) => {
  const page = body?.pagination?.offset || 0;
  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useQuery(
    ["tableContent", tableAccessor, page],
    () => fetcher(`${TABLE_CONTENT_URL}/${tableAccessor}`, { method: 'POST', body: JSON.stringify(body) }),
    {
      enabled: !!tableAccessor,
      keepPreviousData: true,
    }
  )

  const canEdit = data?.userAccess !== "VIEW"

  const tableContentData = useMemo(() => {
    return data as ContentInfo;
  }, [data, tableAccessor]);

  const debouncedRefetcher = debounce(refetch, 1000);
  const debouncedRefetch = useCallback(debouncedRefetcher, [debouncedRefetcher])

  return {
    canEdit,
    tableContentLoading: isLoading,
    tableContent: tableContentData,
    refetchTableContent: debouncedRefetch,
    tableContentError: isError ? data.message : undefined,
  }
}
