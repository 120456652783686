import { Box, Button, Divider, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface FormFilterProps {
  title: string;
  key: string;
  selected: string;
  options: {
    value: string;
    name: string;
  }[];
}

interface FilterFormProps {
  filterContent: FormFilterProps[];
  canSubmit?: boolean;
  onFilterClick?: () => void;
}

export interface FormFilterValue {
  [key: string]: string;
}

export function useFormFilter(initialFilter: FormFilterValue = {}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filter = useMemo(() => {
    return Object.fromEntries(searchParams.entries());
  }, [searchParams]);

  const updateFilter = useCallback((key: string, value: string) => {
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set(key, value);
      
      // Remove params after the changed one
      // const keys = Array.from(newParams.keys());
      // const keyIndex = keys.indexOf(key);
      // keys.slice(keyIndex + 1).forEach(k => newParams.delete(k));

      return newParams;
    }, { replace: true });
  }, [setSearchParams]);

  const clearFilter = useCallback(() => {
    setSearchParams({}, { replace: true });
  }, [setSearchParams]);

  return {
    filter,
    updateFilter,
    clearFilter
  };
}

export const FilterForm = (props: FilterFormProps) => {
  const { filterContent, onFilterClick, canSubmit } = props;
  const { t } = useTranslation("translations");
  const { filter, updateFilter } = useFormFilter();

  const handleFilterChange = useCallback((key: string, value: string) => {
    updateFilter(key, value);
  }, [updateFilter]);

  const handleFilterClick = useCallback(() => {
    if (canSubmit && onFilterClick) {
      updateFilter('submit_form', '1');
      onFilterClick();
    }
  }, [canSubmit, onFilterClick, updateFilter]);

  return (
    <Box>
      <Box height={'calc(100vh - 96px)'} p={1}>
        {filterContent.map(c => {
          const { title, options, key } = c;
          return (
            <Box key={`select_${key}`} mb={2}>
              <InputLabel shrink>{title}</InputLabel>
              <Select
                fullWidth
                variant="standard"
                value={filter[key] || ""}
                onChange={(event: SelectChangeEvent<string>) => handleFilterChange(key, event.target.value)}
              >
                {options.map((o, index) => (
                  <MenuItem key={`option_${index}`} value={o.value}>{o.name}</MenuItem>
                ))}
              </Select>
            </Box>
          )
        })}
      </Box>
      <Divider />
      <Box p={1}>
        <Button type="submit" variant="contained" color="info" fullWidth disabled={!canSubmit} onClick={handleFilterClick}>
          {t("filter")}
        </Button>
      </Box>
    </Box>
  )
}