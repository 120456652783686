import { Routes, Route } from "react-router-dom";

import "./App.css";
import { lightTheme } from "./assets/theme";
import MenuAppBar from "./components/MenuAppBar";
import {
  useIsAuthenticated, useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { SignInView } from "./views/auth/login";
import DataTableCard from "./views/data-tables";
import { first } from "lodash";
import { useCallback, useEffect } from "react";
import { startSession } from "./services/requests/session";
import { useCookies } from "react-cookie";
import { useSession, useUserInfoByUsername } from "./hooks/userHooks";
import Home from "./views/home";
import { CssBaseline, ThemeProvider } from "@mui/material";
import i18n from 'i18next';
import { loginRequest } from "./services/azureAuth";
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('e5178e5ce247b94d5eb9bb64241bb71cTz05MDIxNyxFPTE3NDcyMzE5ODkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


function App() {
  const azureAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const [cookies, setCookie, removeCookie] = useCookies(["adpt-auth-token"]);

  const { session, mutateSession } = useSession(azureAuthenticated);
  const { user, databases } = useUserInfoByUsername(session?.username);
  const currentAccount = first(accounts);

  const handleStartSession = useCallback(async () => {
    if (cookies["adpt-auth-token"]) return;

    const latestSessionRaw = localStorage.getItem("adpt-session");

    const latestSession = JSON.parse(latestSessionRaw || "{}");

    const groups = await instance.acquireTokenSilent({ ...loginRequest, account: currentAccount }).then((response) => {
      return ((response.account?.idTokenClaims as any)?.groups ?? []) as string[];
    }).catch((e) => {
      return [] as string[];
    });

    const newSession = await startSession({
      database: latestSession?.database,
      view: latestSession?.view,
      serverId: latestSession?.serverId,
      username: currentAccount!.username,
      groups: groups
    });

    if (newSession?.token) {
      setCookie("adpt-auth-token", newSession?.token);
      console.log(session);
    }
    if (newSession?.session) {
      localStorage.setItem("adpt-session", JSON.stringify(newSession.session));
    }
    mutateSession();
    i18n.changeLanguage(newSession?.language ?? navigator.language);
  }, [currentAccount, setCookie, cookies, mutateSession, instance]);

  useEffect(() => {
    if (azureAuthenticated ) {
      handleStartSession();
    } else {
      removeCookie("adpt-auth-token");
    }
  }, [azureAuthenticated, removeCookie, handleStartSession]);
  
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <MenuAppBar
          user={user}
          databases={databases?.map(db => {
            db.isActive = db.serverId === session?.serverId && db.name === session?.database && db.view === session?.view;
            return db;
          })}
          isAuthenticated={!!session}
          mutateSession={mutateSession}
        />
        <UnauthenticatedTemplate>
          <Routes>
            <Route path={"/"} element={<SignInView />} />
          </Routes>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Routes>
            <Route path={"content/:tableAccessor"} element={session?.database ? <DataTableCard type="TABLE" /> : null} />
            <Route path={"content_form/:tableAccessor"} element={session?.database ? <DataTableCard type="FORM" /> : null} />
            <Route path={"/"} element={user ? <Home username={user.username} database={session?.database} /> : null} />
          </Routes>
        </AuthenticatedTemplate>
      </ThemeProvider>
    </>
  );
}

export default App;
